/**
 * Variables
 * ----------------------------------------------------------------------------
 */

/* Colors */
$alabaster: #fafafa;
$gallery: #ededed;
$gray: #818181;
$mine-shaft: #333333;
$picton-blue: #22b3eb;
$silver: #c6c6c6;
$tundora: #474747;
$white: #ffffff;
