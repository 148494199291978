/**
 * Icons | Font Awesome by Dave Gandy | https://fontawesome.io
 * ----------------------------------------------------------------------------
 */

@font-face {
  font-family: 'fontawesome';
  src: url('../fonts/fontawesome.eot?lc16yo');
  src: url('../fonts/fontawesome.eot?lc16yo#iefix') format('embedded-opentype'), url('../fonts/fontawesome.ttf?lc16yo') format('truetype'),
    url('../fonts/fontawesome.woff?lc16yo') format('woff'), url('../fonts/fontawesome.svg?lc16yo#fontawesome') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

[class^='fa-'],
[class*=' fa-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fontawesome' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-star:before {
  content: '\f005';
}
.fa-close:before {
  content: '\f00d';
}
.fa-map-marker:before {
  content: '\f041';
}
.fa-chevron-left:before {
  content: '\f053';
}
.fa-chevron-right:before {
  content: '\f054';
}
.fa-chevron-up:before {
  content: '\f077';
}
.fa-chevron-down:before {
  content: '\f078';
}
.fa-bar-chart:before {
  content: '\f080';
}
.fa-twitter:before {
  content: '\f099';
}
.fa-facebook:before {
  content: '\f09a';
}
.fa-feed:before {
  content: '\f09e';
}
.fa-link:before {
  content: '\f0c1';
}
.fa-navicon:before {
  content: '\f0c9';
}
.fa-google-plus:before {
  content: '\f0d5';
}
.fa-envelope:before {
  content: '\f0e0';
}
.fa-linkedin:before {
  content: '\f0e1';
}
.fa-github-alt:before {
  content: '\f113';
}
.fa-code:before {
  content: '\f121';
}
.fa-xing:before {
  content: '\f168';
}
.fa-youtube-play:before {
  content: '\f16a';
}
.fa-stack-overflow:before {
  content: '\f16c';
}
.fa-instagram:before {
  content: '\f16d';
}
.fa-flickr:before {
  content: '\f16e';
}
.fa-tumblr:before {
  content: '\f173';
}
.fa-dribbble:before {
  content: '\f17d';
}
.fa-weibo:before {
  content: '\f18a';
}
.fa-behance:before {
  content: '\f1b4';
}
.fa-soundcloud:before {
  content: '\f1be';
}
.fa-vine:before {
  content: '\f1ca';
}
.fa-codepen:before {
  content: '\f1cb';
}
.fa-weixin:before {
  content: '\f1d7';
}
.fa-pinterest-p:before {
  content: '\f231';
}
.fa-whatsapp:before {
  content: '\f232';
}
.fa-medium:before {
  content: '\f23a';
}
.fa-500px:before {
  content: '\f26e';
}
.fa-vimeo:before {
  content: '\f27d';
}
.fa-mixcloud:before {
  content: '\f289';
}
